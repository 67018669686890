import * as EmojiAware from 'emoji-aware'
import {
  component,
  computed,
  observable,
  observableArray,
  subscribe,
} from 'knockout-decorators'
import { compareTwoStrings } from 'string-similarity'

import source from './page-header.html?raw'
import './page-header.less'

import { EventItem, Location, Organization, SearchResults } from '@tixa/schema'
import { Analytics, Authentication, ErrorReporter } from '~/utils'
import { pageState } from '~/store/page'

import CreditCardImages from '../../images/cards.svg?raw'
import SearchIcon from '../../images/icons/search.svg?raw'
import FacebookLogo from '../../images/logo-facebook.svg?raw'
import InstagramLogo from '../../images/logo-instagram.svg?raw'
import TixaBlogLogo from '../../images/logo-tixablog.svg?raw'
import LogoImage from '../../images/tixa-logo.svg?raw'
import UserIcon from '../../images/user-icon-2.svg?raw'
import { AppNavigation } from '~/utils/navigation'

@component('page-header', source)
export class PageHeader {
  @computed get overallResults(): number {
    return (
      this.eventResults.length +
      this.locationResults.length +
      this.organizerResults.length
    )
  }

  @computed get showSearch(): boolean {
    return this.searchTerm.length >= this.searchMinCharCount
  }

  @computed get showNoresults(): boolean {
    return (
      (this.searchTerm.length >= this.searchMinCharCount ||
        this.overallResults === 0) &&
      this.searchRunning === false
    )
  }

  @computed get showClose(): boolean {
    return (
      this.showSearch && false === this.searchRunning && this.overallResults > 0
    )
  }

  @computed
  get isLoginActive(): boolean {
    return this.navState === 'login'
  }

  @computed
  get isLanguageActive(): boolean {
    return this.navState === 'language'
  }

  @observable public searchFocus = false
  @observable public searchRunning = false
  @observable public searchTerm = ''
  @observableArray public eventResults: EventItem[] = []
  @observableArray public locationResults: Location[] = []
  @observableArray public organizerResults: Organization[] = []

  @observable public isLoggedIn = false

  // Mobile nav

  @observable public isHomepage: boolean = window.location.pathname.length <= 1
  @observable public navState = ''
  @observable public showCollapse = false
  @observable public isSearchActive = false

  private Tracker = Analytics.getInstance()
  private Auth = Authentication.getInstance()

  private readonly searchMinCharCount = 3
  private readonly searchMaxResults = 10

  private lastCancelToken = new AbortController()

  private logoImage = LogoImage
  private searchIcon = SearchIcon
  private userIcon = UserIcon
  private creditCardImages = CreditCardImages
  private facebookLogo = FacebookLogo
  private instagramLogo = InstagramLogo
  private blogLogo = TixaBlogLogo

  constructor() {
    const searchEl = window.document.getElementById(
      'search'
    ) as HTMLInputElement
    if (searchEl) {
      searchEl.onfocus = () => {
        this.searchFocus = true
      }
      searchEl.onblur = () => {
        this.searchFocus = false
        setTimeout(() => {
          ;(
            document.querySelector('img.background') as HTMLImageElement
          ).style.transform = `scale(1)`
        }, 200)
      }
    }
    subscribe<string>(
      () => this.searchTerm,
      (newTerm) => this.search(newTerm)
    )
    this.isLoggedIn = this.Auth.LoggedIn()
  }

  private search(term: string) {
    this.cancelLastSearch()
    if (term.length >= this.searchMinCharCount) {
      this.searchRunning = true
      const searchTerm = EmojiAware.withoutEmoji(term).join('')

      pageState.api.search
        .search(searchTerm, this.lastCancelToken)
        .then((value) => {
          this.clearResults()
          this.processSearchResults(term, value)
          this.searchRunning = false
        })
        .catch((error) => {
          if (error.name !== 'CanceledError') {
            ErrorReporter.exception(error)
          }
          this.clearResults()
          this.searchRunning = false
        })

      if (this.Tracker && this.Tracker.searched) {
        this.Tracker.searched(term)
      }
    } else {
      this.clearResults()
    }
  }

  private processSearchResults(term: string, results: SearchResults) {
    if (!results) {
      return
    }
    this.locationResults = this.similaritySorter<Location>(
      term,
      results.locations,
      (location) => location.name
    ).slice(0, 5)
    this.organizerResults = this.similaritySorter<Organization>(
      term,
      results.organizers,
      (organization) => organization.name
    ).slice(0, 5)

    const resultsSoFar =
      this.locationResults.length + this.organizerResults.length
    this.eventResults = this.similaritySorter<EventItem>(
      term,
      results.events,
      (event) => event.name
    ).slice(0, this.searchMaxResults - resultsSoFar)
  }

  private similaritySorter<T>(
    term: string,
    array: T[],
    key: (obj: T) => string
  ): T[] {
    const keySimilarity = array.map((item) =>
      compareTwoStrings(term, key(item))
    )
    return array.sort((a, b) =>
      this.sortComparator<T>(a, b, (obj) => keySimilarity[array.indexOf(obj)])
    )
  }

  private sortComparator<T>(a: T, b: T, key: (obj: T) => number): number {
    return key(a) > key(b) ? -1 : key(a) > key(b) ? 1 : 0
  }

  private clearResults() {
    this.eventResults.length = 0
    this.locationResults.length = 0
    this.organizerResults.length = 0
  }

  private cancelLastSearch() {
    if (this.lastCancelToken) {
      this.lastCancelToken.abort()
      this.searchRunning = false
      this.lastCancelToken = new AbortController()
    }
  }

  private formatDate(event: EventItem) {
    // return fecha.format(new Date(date), "MMMM DD");
    return event.customDate ? event.customDate : event.startDate
  }

  private async ticketsClicked() {
    if (this.Auth.LoggedIn()) {
      this.redirectToTickets()
    } else {
      this.Auth.Login(() => this.redirectToTickets())
    }
  }

  private async toggleCollapse() {
    this.showCollapse = !this.showCollapse
    this.isSearchActive = false
    await this.Auth.HideDiag()
    pageState.blockAnimations = this.showCollapse
  }

  private async profileClicked() {
    await this.Auth.ShowProfileDiag()
  }

  private redirectToTickets() {
    AppNavigation.tickets()
  }

  private async otherLangClicked() {
    await AppNavigation.switchLanguage()
  }

  private closeSearch() {
    if (this.lastCancelToken) {
      this.lastCancelToken.abort()
    }
    this.clearResults()
    this.searchTerm = ''
  }

  private homeClicked() {
    window.location.href = '/'
  }

  private async searchClicked() {
    this.isSearchActive = !this.isSearchActive
    this.showCollapse = false
    await this.Auth.HideDiag()
    pageState.blockAnimations = this.isSearchActive
  }

  private languageClicked() {
    this.navState = this.navState === 'language' ? '' : 'language'
  }
}
