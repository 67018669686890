import * as ko from 'knockout'
import { observable } from 'knockout-decorators'
import '../utils/ko-extenders'

import { Graphics } from '../utils/graphics'

import '../styles/bootstrap.scss'
import './page.less'

import '../components/page-alert/page-alert'
import '../components/page-footer/page-footer'
import '../components/page-header/page-header'
import { PaymentPrechecker } from '../components/payment-checker/payment-prechecker'
import { ErrorReporter } from '../utils/error-reporting'

import '@fontsource/nunito/variable.css'

export abstract class Page {
  @observable public robotsMeta =
    import.meta.env.MODE === 'production'
      ? 'index, follow'
      : 'noindex, nofollow'

  constructor() {
    Graphics.init()
    ErrorReporter.init()

    this.addMetaTag()

    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        ko.applyBindings(this)
        ko.applyBindings(this, document.head)
      }
    }

    window.addEventListener('load', async () => {
      await PaymentPrechecker.check()
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (const registration of registrations) {
            registration.unregister()
          }
        })
      }
    })
  }

  private addMetaTag() {
    let metaTag = document.querySelector('meta[name="robots"]')

    if (!metaTag) {
      metaTag = document.createElement('meta')
      metaTag.setAttribute('name', 'robots')
      document.head.appendChild(metaTag)
    }

    ko.applyBindingsToNode(
      metaTag,
      { attr: { content: this.robotsMeta } },
      this
    )
  }
}
