import * as ko from 'knockout'
import smartcrop, { CropResult } from 'smartcrop'

import SrcSets from './srcSets'

export class Graphics {
  public static get hasSrcSet(): boolean {
    return !this.isFacebookApp() && 'srcset' in document.createElement('img')
  }

  private static isFacebookApp() {
    const ua = navigator.userAgent || navigator.vendor
    return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1
  }

  public static scaleAndCenter(
    mode: 'cover' | 'contain',
    containerWidth: number,
    containerHeight: number,
    imageWidth: number,
    imageHeight: number
  ): {
    bottom: number
    height: number
    left: number
    right: number
    top: number
    width: number
  } {
    const containerRatio = containerWidth / containerHeight
    const imageRatio = imageWidth / imageHeight

    const comparison = (x: number, y: number) => {
      return mode === 'cover' ? x < y : x > y
    }

    const outputRatio = comparison(imageRatio, containerRatio)
      ? containerWidth / imageWidth
      : containerHeight / imageHeight

    const newWidth = imageWidth * outputRatio
    const newHeight = imageHeight * outputRatio
    const newX = containerWidth / 2 - newWidth / 2
    const newY = containerHeight / 2 - newHeight / 2

    return {
      bottom: newY,
      height: newHeight,
      left: newX,
      right: newX,
      top: newY,
      width: newWidth,
    }
  }

  public static fillContext(
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    colormode: GlobalCompositeOperation,
    alpha: number,
    gradient: CanvasGradient | string,
    x: number,
    y: number,
    w: number,
    h: number
  ): void {
    ctx.globalCompositeOperation = colormode
    ctx.globalAlpha = alpha
    ctx.fillStyle = gradient
    ctx.fillRect(x, y, w, h)
    ctx.globalCompositeOperation = 'color'
    ctx.globalAlpha = 1
  }

  // COLOR OPERATIONS
  public static isImageDark(image: HTMLImageElement): number {
    const nCanvas = document.createElement('canvas')
    nCanvas.width = image.width
    nCanvas.height = image.height

    const nCtx = nCanvas.getContext('2d')
    if (nCtx === null) {
      console.error('Could not get 2d context')
      return 0
    }
    nCtx.drawImage(image, 0, 0, nCanvas.width, nCanvas.height)

    const data = nCtx.getImageData(0, 0, nCanvas.width, nCanvas.height).data
    let colorSum = 0
    let r = 0
    let g = 0
    let b = 0
    let avg = 0

    for (let x = 0, len = data.length; x < len; x += 4) {
      r = data[x]
      g = data[x + 1]
      b = data[x + 2]
      avg = Math.floor((r + g + b) / 3)
      colorSum += avg
    }

    const brightness = Math.floor(colorSum / (nCanvas.width * nCanvas.height))

    return brightness
  }
  public static rgbToRgba(color: number[], opacity: number): string {
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`
  }

  private static normalizeHue(hue: number): number {
    return ((hue % 360) + 360) % 360
  }

  /**
   * This function sorts HSL Hue values in a way that ensures that different
   * hues follow each other in the array.
   */
  public static sortHuesForDifference(hues: number[]): number[] {
    const startHue = hues[0]
    const rotated = [...hues].map((hue) =>
      Graphics.normalizeHue(hue - startHue)
    )
    const sorted = [...rotated].sort((a, b) => a - b)
    const sortedIndexInRotated = sorted.map((hue) => rotated.indexOf(hue))
    return hues.map((_, i) => hues[sortedIndexInRotated[i]])
  }

  // SRCSET GENERATION
  public static getSrcWithSize(path: string, width: number): string {
    const pathParts = path.split('/')
    pathParts.splice(pathParts.length - 1, 0, width.toString())
    return pathParts.join('/')
  }
  public static createSrcSet(path: string, set: number[]): string {
    return set
      .map(
        (width) =>
          Graphics.getSrcWithSize(path, width) + ' ' + width.toString() + 'w'
      )
      .join(', ')
  }
  public static getClosestWidth(set: number[], goal: number): number {
    return set.reduce((prev, curr) => {
      return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
    })
  }
  public static checkDeviceCompatibility(): void {
    const testEl = document.createElement('x-test')
    const supportsWebkitBackgroundClipText =
      typeof testEl.style.webkitBackgroundClip !== 'undefined' &&
      ((testEl.style.webkitBackgroundClip = 'text'),
      testEl.style.webkitBackgroundClip === 'text')

    if (false === supportsWebkitBackgroundClipText) {
      document.body.classList.add('notextbgclip')
    }
  }

  // KNOCKOUT
  public static initKoExtenders(): void {
    const hasSrcSet = Graphics.hasSrcSet
    ko.bindingHandlers.crop = {
      init: (element: HTMLImageElement) => {
        element.style.opacity = '0'
        element.addEventListener('load', () => {
          const cropOptions = {
            height: element.clientHeight,
            width: element.clientWidth,
          }

          smartcrop.crop(element, cropOptions).then((result: CropResult) => {
            ;(element.style as CSSStyleDeclaration).objectPosition =
              '0 -' + result.topCrop.y.toString() + 'px'
            element.style.opacity = '1'
          })
        })
        const src = element.getAttribute('data-src')
        element.src = src !== null ? src : ''
      },
    }
    ko.bindingHandlers.srcset = {
      init: (element: HTMLImageElement, valueAccessor) =>
        Graphics.decorateWithSrcSet(element, valueAccessor(), false, hasSrcSet),
      update: (element, valueAccessor) =>
        Graphics.decorateWithSrcSet(element, valueAccessor(), false, hasSrcSet),
    }
    ko.bindingHandlers.logosrcset = {
      init: (element: HTMLImageElement, valueAccessor) =>
        Graphics.decorateWithSrcSet(element, valueAccessor(), true, hasSrcSet),
      update: (element, valueAccessor) =>
        Graphics.decorateWithSrcSet(element, valueAccessor(), true, hasSrcSet),
    }
    ko.bindingHandlers.autosize = {
      init: (element: HTMLImageElement) => {
        element.addEventListener('load', () => {
          element.width = element.clientWidth
          element.height = element.clientHeight
        })
      },
    }
  }

  // INTERSECTION OBSERVER
  public static initObserver(): void {
    const hasIntersection = 'IntersectionObserver' in window
    const observerAttr = 'observedAs'
    const valueAccessors = new Map<string, any>()
    const viewModels = new Map<string, any>()

    let observedIncrement = 0
    let observer: IntersectionObserver

    if (hasIntersection) {
      const observeHandler = (entries: IntersectionObserverEntry[]) => {
        entries
          .filter(
            (entry) =>
              entry.isIntersecting === undefined ||
              entry.isIntersecting === true
          )
          .forEach((entry) => {
            const id = entry.target.getAttribute(observerAttr)
            if (null !== id) {
              valueAccessors.get(id)().apply(viewModels.get(id), [entry.target])
            }
          })
      }
      observer = new IntersectionObserver(observeHandler, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      })
    }

    ko.bindingHandlers.lazy = {
      init: (element: HTMLElement, valueAccessor, allBindings, viewModel) => {
        if (hasIntersection) {
          observedIncrement++
          element.setAttribute(observerAttr, observedIncrement.toString())
          valueAccessors.set(observedIncrement.toString(), valueAccessor)
          viewModels.set(observedIncrement.toString(), viewModel)
          observer.observe(element)
        } else {
          valueAccessor().apply(viewModel, [element])
        }
      },
    }
  }

  public static init(): void {
    Graphics.initKoExtenders()
    Graphics.initObserver()
    window.addEventListener('load', () => {
      Graphics.checkDeviceCompatibility()
      if (this.hasSrcSet) {
        import('lazysizes')
      }
    })
  }

  private static consoleColor(color: string, name: string) {
    // tslint:disable-next-line:no-console
    console.log('%c ' + name + ' ', 'background: ' + color + '; color: white')
  }
  private static decorateWithSrcSet(
    element: Element,
    url: string,
    isLogo: boolean,
    hasSrcSet: boolean
  ) {
    if (hasSrcSet) {
      if (isLogo) {
        element.setAttribute('data-src', url)
        element.setAttribute(
          'data-srcset',
          Graphics.createSrcSet(url, SrcSets.logo)
        )
      } else {
        element.setAttribute('data-src', url)
        element.setAttribute(
          'src',
          Graphics.getSrcWithSize(url, SrcSets.coverLq)
        )
        element.setAttribute(
          'data-srcset',
          Graphics.createSrcSet(url, SrcSets.cover)
        )
        element.setAttribute('data-sizes', 'auto')
      }
      element.classList.add('lazyload')
    } else {
      element.setAttribute('src', url)
      element.classList.add('lazyload')
      element.classList.add('lazyloaded')
    }
  }
}
